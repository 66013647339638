import React, {useEffect} from 'react';
import { useRouter } from 'next/router';

const Flowers = () => {
    const router = useRouter()

    useEffect(() => {
        const flowers = document.querySelectorAll('.flower_container')
        
        if(router.pathname !== '/') {
            flowers.forEach(item => item.classList.add('from_top'))
        }

    },[router.pathname])

    return (
        <>

        <div className="flower_container  flower_container_four  small">
            <svg className='flower  flower_moves' width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="flower">
                  <path id="Ellipse14" d="M7.00421 5.33129C7.87813 6.09058 8.44647 6.99999 8.67563 7.83624C8.9059 8.67654 8.78364 9.38205 8.38406 9.84195C7.98449 10.3018 7.30298 10.5215 6.43875 10.4108C5.57869 10.3007 4.59881 9.86498 3.72489 9.10569C2.85097 8.3464 2.28262 7.43699 2.05346 6.60074C1.82319 5.76044 1.94546 5.05493 2.34503 4.59503C2.74461 4.13513 3.42612 3.91552 4.29035 4.02616C5.15041 4.13626 6.13029 4.572 7.00421 5.33129Z" />
                  <path id="Ellipse15" d="M14.1133 6.75638C13.5164 7.76659 12.714 8.50006 11.9221 8.88207C11.1265 9.26593 10.3999 9.27391 9.86721 8.95917C9.33455 8.64443 8.991 8.0041 8.94339 7.12199C8.89601 6.24412 9.15138 5.18741 9.74829 4.17721C10.3452 3.167 11.1476 2.43353 11.9394 2.05152C12.7351 1.66766 13.4617 1.65967 13.9944 1.97441C14.527 2.28915 14.8706 2.92948 14.9182 3.8116C14.9656 4.68947 14.7102 5.74617 14.1133 6.75638Z" />
                  <path id="Ellipse4" d="M8.9681 4.21271C9.51035 5.23556 9.71348 6.28855 9.62645 7.15125C9.539 8.01813 9.17111 8.63242 8.63284 8.91777C8.09457 9.20313 7.37968 9.16287 6.61311 8.74873C5.85025 8.33659 5.09273 7.5775 4.55048 6.55465C4.00823 5.5318 3.8051 4.47881 3.89213 3.61611C3.97958 2.74923 4.34747 2.13495 4.88574 1.84959C5.42401 1.56423 6.1389 1.6045 6.90547 2.01863C7.66833 2.43077 8.42585 3.18986 8.9681 4.21271Z" />
                  <path id="Ellipse5" d="M12.423 4.98857C12.454 6.14585 12.1678 7.17935 11.7062 7.91341C11.2425 8.65102 10.6399 9.03769 10.0308 9.05397C9.42182 9.07026 8.79938 8.71635 8.29687 8.00458C7.79679 7.29624 7.45575 6.27952 7.4248 5.12223C7.39386 3.96495 7.68007 2.93145 8.14158 2.1974C8.60532 1.45979 9.20796 1.07312 9.81698 1.05683C10.426 1.04054 11.0484 1.39445 11.5509 2.10622C12.051 2.81456 12.3921 3.83129 12.423 4.98857Z" />
                  <path id="Ellipse6" d="M13.9839 9.87344C15.0845 10.2325 15.9621 10.8488 16.4988 11.5298C17.0381 12.2141 17.2003 12.9116 17.0113 13.4908C16.8223 14.07 16.2801 14.5376 15.441 14.7722C14.606 15.0057 13.5338 14.9859 12.4332 14.6269C11.3326 14.2678 10.455 13.6515 9.91825 12.9705C9.37895 12.2862 9.21684 11.5887 9.4058 11.0096C9.59475 10.4304 10.137 9.96271 10.9761 9.72809C11.8111 9.49459 12.8833 9.51438 13.9839 9.87344Z" />
                  <path id="Ellipse7" d="M14.8234 9.08101C13.8688 9.73591 12.8457 10.0573 11.9787 10.0688C11.1074 10.0804 10.4554 9.78464 10.1107 9.28225C9.76607 8.77987 9.72489 8.06503 10.0493 7.2564C10.3721 6.45166 11.0403 5.61284 11.9949 4.95794C12.9496 4.30303 13.9727 3.98163 14.8397 3.97013C15.7109 3.95856 16.363 4.25431 16.7076 4.75669C17.0523 5.25908 17.0935 5.97391 16.7691 6.78255C16.4462 7.58728 15.7781 8.4261 14.8234 9.08101Z" />
                  <path id="Ellipse11" d="M4.57173 12.2002C5.11992 11.1805 5.88185 10.4258 6.6471 10.0181C7.41606 9.60847 8.13117 9.57237 8.66778 9.86086C9.20438 10.1493 9.56868 10.7658 9.65108 11.6331C9.73308 12.4963 9.52383 13.5481 8.97563 14.5678C8.42743 15.5875 7.66551 16.3421 6.90025 16.7498C6.13129 17.1595 5.41618 17.1956 4.87958 16.9071C4.34297 16.6186 3.97867 16.0022 3.89627 15.1348C3.81427 14.2716 4.02353 13.2198 4.57173 12.2002Z" />
                  <path id="Ellipse12" d="M7.0103 14.1C6.87837 12.9498 7.07319 11.8953 7.46881 11.1237C7.86634 10.3484 8.4329 9.91055 9.03816 9.84111C9.64343 9.77168 10.2944 10.0699 10.8572 10.735C11.4172 11.3969 11.8458 12.38 11.9777 13.5301C12.1097 14.6803 11.9148 15.7349 11.5192 16.5064C11.1217 17.2817 10.5551 17.7196 9.94987 17.789C9.3446 17.8584 8.69362 17.5602 8.13085 16.8951C7.5708 16.2332 7.14224 15.2501 7.0103 14.1Z" />
                  <path id="Ellipse13" d="M4.51578 9.40437C5.64942 9.1696 6.71722 9.26886 7.52122 9.59354C8.32911 9.91978 8.81609 10.4447 8.93964 11.0413C9.06318 11.6378 8.82472 12.313 8.21284 12.9333C7.60391 13.5505 6.66336 14.0657 5.52972 14.3005C4.39608 14.5352 3.32828 14.436 2.52428 14.1113C1.71639 13.7851 1.2294 13.2602 1.10586 12.6636C0.982312 12.067 1.22078 11.3919 1.83266 10.7716C2.44158 10.1543 3.38214 9.63913 4.51578 9.40437Z" />
                  <path id="Ellipse9" d="M5.55268 6.43114C6.68473 6.6735 7.6218 7.19497 8.22658 7.81632C8.83428 8.44067 9.06822 9.1174 8.94067 9.71314C8.81313 10.3089 8.32264 10.8305 7.51257 11.1513C6.70642 11.4706 5.63798 11.5627 4.50594 11.3203C3.37389 11.078 2.43682 10.5565 1.83204 9.93516C1.22433 9.31081 0.990403 8.63408 1.11795 8.03834C1.24549 7.4426 1.73598 6.92097 2.54605 6.60015C3.3522 6.28087 4.42064 6.18877 5.55268 6.43114Z" />
                  <path id="Ellipse10" d="M14.6933 7.66251C15.8502 7.70379 16.8639 8.0539 17.5677 8.56029C18.275 9.06913 18.6233 9.69471 18.6016 10.3036C18.5799 10.9124 18.1878 11.5116 17.4461 11.9687C16.7079 12.4236 15.6719 12.7006 14.515 12.6593C13.358 12.618 12.3444 12.2679 11.6405 11.7615C10.9333 11.2527 10.5849 10.6271 10.6067 10.0183C10.6284 9.40943 11.0204 8.81026 11.7622 8.35313C12.5003 7.89819 13.5363 7.62122 14.6933 7.66251Z" />
                  <path id="Ellipse8" d="M13.9681 12.2103C14.5103 13.2331 14.7135 14.2861 14.6264 15.1488C14.539 16.0157 14.1711 16.63 13.6328 16.9153C13.0946 17.2007 12.3797 17.1604 11.6131 16.7463C10.8502 16.3341 10.0927 15.5751 9.55048 14.5522C9.00823 13.5294 8.8051 12.4764 8.89213 11.6137C8.97958 10.7468 9.34747 10.1325 9.88574 9.84715C10.424 9.56179 11.1389 9.60205 11.9055 10.0162C12.6683 10.4283 13.4258 11.1874 13.9681 12.2103Z" />
                  <circle id="Ellipse3" cx="9.49414" cy="9.49951" r="3" />
                </g>
            </svg>
          </div>  

          <div className="flower_container  flower_container_five  middle">
            <svg className='flower  flower_moves' width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="flower">
                  <path id="Ellipse14" d="M7.00421 5.33129C7.87813 6.09058 8.44647 6.99999 8.67563 7.83624C8.9059 8.67654 8.78364 9.38205 8.38406 9.84195C7.98449 10.3018 7.30298 10.5215 6.43875 10.4108C5.57869 10.3007 4.59881 9.86498 3.72489 9.10569C2.85097 8.3464 2.28262 7.43699 2.05346 6.60074C1.82319 5.76044 1.94546 5.05493 2.34503 4.59503C2.74461 4.13513 3.42612 3.91552 4.29035 4.02616C5.15041 4.13626 6.13029 4.572 7.00421 5.33129Z" />
                  <path id="Ellipse15" d="M14.1133 6.75638C13.5164 7.76659 12.714 8.50006 11.9221 8.88207C11.1265 9.26593 10.3999 9.27391 9.86721 8.95917C9.33455 8.64443 8.991 8.0041 8.94339 7.12199C8.89601 6.24412 9.15138 5.18741 9.74829 4.17721C10.3452 3.167 11.1476 2.43353 11.9394 2.05152C12.7351 1.66766 13.4617 1.65967 13.9944 1.97441C14.527 2.28915 14.8706 2.92948 14.9182 3.8116C14.9656 4.68947 14.7102 5.74617 14.1133 6.75638Z" />
                  <path id="Ellipse4" d="M8.9681 4.21271C9.51035 5.23556 9.71348 6.28855 9.62645 7.15125C9.539 8.01813 9.17111 8.63242 8.63284 8.91777C8.09457 9.20313 7.37968 9.16287 6.61311 8.74873C5.85025 8.33659 5.09273 7.5775 4.55048 6.55465C4.00823 5.5318 3.8051 4.47881 3.89213 3.61611C3.97958 2.74923 4.34747 2.13495 4.88574 1.84959C5.42401 1.56423 6.1389 1.6045 6.90547 2.01863C7.66833 2.43077 8.42585 3.18986 8.9681 4.21271Z" />
                  <path id="Ellipse5" d="M12.423 4.98857C12.454 6.14585 12.1678 7.17935 11.7062 7.91341C11.2425 8.65102 10.6399 9.03769 10.0308 9.05397C9.42182 9.07026 8.79938 8.71635 8.29687 8.00458C7.79679 7.29624 7.45575 6.27952 7.4248 5.12223C7.39386 3.96495 7.68007 2.93145 8.14158 2.1974C8.60532 1.45979 9.20796 1.07312 9.81698 1.05683C10.426 1.04054 11.0484 1.39445 11.5509 2.10622C12.051 2.81456 12.3921 3.83129 12.423 4.98857Z" />
                  <path id="Ellipse6" d="M13.9839 9.87344C15.0845 10.2325 15.9621 10.8488 16.4988 11.5298C17.0381 12.2141 17.2003 12.9116 17.0113 13.4908C16.8223 14.07 16.2801 14.5376 15.441 14.7722C14.606 15.0057 13.5338 14.9859 12.4332 14.6269C11.3326 14.2678 10.455 13.6515 9.91825 12.9705C9.37895 12.2862 9.21684 11.5887 9.4058 11.0096C9.59475 10.4304 10.137 9.96271 10.9761 9.72809C11.8111 9.49459 12.8833 9.51438 13.9839 9.87344Z" />
                  <path id="Ellipse7" d="M14.8234 9.08101C13.8688 9.73591 12.8457 10.0573 11.9787 10.0688C11.1074 10.0804 10.4554 9.78464 10.1107 9.28225C9.76607 8.77987 9.72489 8.06503 10.0493 7.2564C10.3721 6.45166 11.0403 5.61284 11.9949 4.95794C12.9496 4.30303 13.9727 3.98163 14.8397 3.97013C15.7109 3.95856 16.363 4.25431 16.7076 4.75669C17.0523 5.25908 17.0935 5.97391 16.7691 6.78255C16.4462 7.58728 15.7781 8.4261 14.8234 9.08101Z" />
                  <path id="Ellipse11" d="M4.57173 12.2002C5.11992 11.1805 5.88185 10.4258 6.6471 10.0181C7.41606 9.60847 8.13117 9.57237 8.66778 9.86086C9.20438 10.1493 9.56868 10.7658 9.65108 11.6331C9.73308 12.4963 9.52383 13.5481 8.97563 14.5678C8.42743 15.5875 7.66551 16.3421 6.90025 16.7498C6.13129 17.1595 5.41618 17.1956 4.87958 16.9071C4.34297 16.6186 3.97867 16.0022 3.89627 15.1348C3.81427 14.2716 4.02353 13.2198 4.57173 12.2002Z" />
                  <path id="Ellipse12" d="M7.0103 14.1C6.87837 12.9498 7.07319 11.8953 7.46881 11.1237C7.86634 10.3484 8.4329 9.91055 9.03816 9.84111C9.64343 9.77168 10.2944 10.0699 10.8572 10.735C11.4172 11.3969 11.8458 12.38 11.9777 13.5301C12.1097 14.6803 11.9148 15.7349 11.5192 16.5064C11.1217 17.2817 10.5551 17.7196 9.94987 17.789C9.3446 17.8584 8.69362 17.5602 8.13085 16.8951C7.5708 16.2332 7.14224 15.2501 7.0103 14.1Z" />
                  <path id="Ellipse13" d="M4.51578 9.40437C5.64942 9.1696 6.71722 9.26886 7.52122 9.59354C8.32911 9.91978 8.81609 10.4447 8.93964 11.0413C9.06318 11.6378 8.82472 12.313 8.21284 12.9333C7.60391 13.5505 6.66336 14.0657 5.52972 14.3005C4.39608 14.5352 3.32828 14.436 2.52428 14.1113C1.71639 13.7851 1.2294 13.2602 1.10586 12.6636C0.982312 12.067 1.22078 11.3919 1.83266 10.7716C2.44158 10.1543 3.38214 9.63913 4.51578 9.40437Z" />
                  <path id="Ellipse9" d="M5.55268 6.43114C6.68473 6.6735 7.6218 7.19497 8.22658 7.81632C8.83428 8.44067 9.06822 9.1174 8.94067 9.71314C8.81313 10.3089 8.32264 10.8305 7.51257 11.1513C6.70642 11.4706 5.63798 11.5627 4.50594 11.3203C3.37389 11.078 2.43682 10.5565 1.83204 9.93516C1.22433 9.31081 0.990403 8.63408 1.11795 8.03834C1.24549 7.4426 1.73598 6.92097 2.54605 6.60015C3.3522 6.28087 4.42064 6.18877 5.55268 6.43114Z" />
                  <path id="Ellipse10" d="M14.6933 7.66251C15.8502 7.70379 16.8639 8.0539 17.5677 8.56029C18.275 9.06913 18.6233 9.69471 18.6016 10.3036C18.5799 10.9124 18.1878 11.5116 17.4461 11.9687C16.7079 12.4236 15.6719 12.7006 14.515 12.6593C13.358 12.618 12.3444 12.2679 11.6405 11.7615C10.9333 11.2527 10.5849 10.6271 10.6067 10.0183C10.6284 9.40943 11.0204 8.81026 11.7622 8.35313C12.5003 7.89819 13.5363 7.62122 14.6933 7.66251Z" />
                  <path id="Ellipse8" d="M13.9681 12.2103C14.5103 13.2331 14.7135 14.2861 14.6264 15.1488C14.539 16.0157 14.1711 16.63 13.6328 16.9153C13.0946 17.2007 12.3797 17.1604 11.6131 16.7463C10.8502 16.3341 10.0927 15.5751 9.55048 14.5522C9.00823 13.5294 8.8051 12.4764 8.89213 11.6137C8.97958 10.7468 9.34747 10.1325 9.88574 9.84715C10.424 9.56179 11.1389 9.60205 11.9055 10.0162C12.6683 10.4283 13.4258 11.1874 13.9681 12.2103Z" />
                  <circle id="Ellipse3" cx="9.49414" cy="9.49951" r="3" />
                </g>
            </svg>
          </div>  

          <div className="flower_container  flower_container_one">
            <svg className='flower  flower_moves' width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="flower">
                  <path id="Ellipse14" d="M7.00421 5.33129C7.87813 6.09058 8.44647 6.99999 8.67563 7.83624C8.9059 8.67654 8.78364 9.38205 8.38406 9.84195C7.98449 10.3018 7.30298 10.5215 6.43875 10.4108C5.57869 10.3007 4.59881 9.86498 3.72489 9.10569C2.85097 8.3464 2.28262 7.43699 2.05346 6.60074C1.82319 5.76044 1.94546 5.05493 2.34503 4.59503C2.74461 4.13513 3.42612 3.91552 4.29035 4.02616C5.15041 4.13626 6.13029 4.572 7.00421 5.33129Z" />
                  <path id="Ellipse15" d="M14.1133 6.75638C13.5164 7.76659 12.714 8.50006 11.9221 8.88207C11.1265 9.26593 10.3999 9.27391 9.86721 8.95917C9.33455 8.64443 8.991 8.0041 8.94339 7.12199C8.89601 6.24412 9.15138 5.18741 9.74829 4.17721C10.3452 3.167 11.1476 2.43353 11.9394 2.05152C12.7351 1.66766 13.4617 1.65967 13.9944 1.97441C14.527 2.28915 14.8706 2.92948 14.9182 3.8116C14.9656 4.68947 14.7102 5.74617 14.1133 6.75638Z" />
                  <path id="Ellipse4" d="M8.9681 4.21271C9.51035 5.23556 9.71348 6.28855 9.62645 7.15125C9.539 8.01813 9.17111 8.63242 8.63284 8.91777C8.09457 9.20313 7.37968 9.16287 6.61311 8.74873C5.85025 8.33659 5.09273 7.5775 4.55048 6.55465C4.00823 5.5318 3.8051 4.47881 3.89213 3.61611C3.97958 2.74923 4.34747 2.13495 4.88574 1.84959C5.42401 1.56423 6.1389 1.6045 6.90547 2.01863C7.66833 2.43077 8.42585 3.18986 8.9681 4.21271Z" />
                  <path id="Ellipse5" d="M12.423 4.98857C12.454 6.14585 12.1678 7.17935 11.7062 7.91341C11.2425 8.65102 10.6399 9.03769 10.0308 9.05397C9.42182 9.07026 8.79938 8.71635 8.29687 8.00458C7.79679 7.29624 7.45575 6.27952 7.4248 5.12223C7.39386 3.96495 7.68007 2.93145 8.14158 2.1974C8.60532 1.45979 9.20796 1.07312 9.81698 1.05683C10.426 1.04054 11.0484 1.39445 11.5509 2.10622C12.051 2.81456 12.3921 3.83129 12.423 4.98857Z" />
                  <path id="Ellipse6" d="M13.9839 9.87344C15.0845 10.2325 15.9621 10.8488 16.4988 11.5298C17.0381 12.2141 17.2003 12.9116 17.0113 13.4908C16.8223 14.07 16.2801 14.5376 15.441 14.7722C14.606 15.0057 13.5338 14.9859 12.4332 14.6269C11.3326 14.2678 10.455 13.6515 9.91825 12.9705C9.37895 12.2862 9.21684 11.5887 9.4058 11.0096C9.59475 10.4304 10.137 9.96271 10.9761 9.72809C11.8111 9.49459 12.8833 9.51438 13.9839 9.87344Z" />
                  <path id="Ellipse7" d="M14.8234 9.08101C13.8688 9.73591 12.8457 10.0573 11.9787 10.0688C11.1074 10.0804 10.4554 9.78464 10.1107 9.28225C9.76607 8.77987 9.72489 8.06503 10.0493 7.2564C10.3721 6.45166 11.0403 5.61284 11.9949 4.95794C12.9496 4.30303 13.9727 3.98163 14.8397 3.97013C15.7109 3.95856 16.363 4.25431 16.7076 4.75669C17.0523 5.25908 17.0935 5.97391 16.7691 6.78255C16.4462 7.58728 15.7781 8.4261 14.8234 9.08101Z" />
                  <path id="Ellipse11" d="M4.57173 12.2002C5.11992 11.1805 5.88185 10.4258 6.6471 10.0181C7.41606 9.60847 8.13117 9.57237 8.66778 9.86086C9.20438 10.1493 9.56868 10.7658 9.65108 11.6331C9.73308 12.4963 9.52383 13.5481 8.97563 14.5678C8.42743 15.5875 7.66551 16.3421 6.90025 16.7498C6.13129 17.1595 5.41618 17.1956 4.87958 16.9071C4.34297 16.6186 3.97867 16.0022 3.89627 15.1348C3.81427 14.2716 4.02353 13.2198 4.57173 12.2002Z" />
                  <path id="Ellipse12" d="M7.0103 14.1C6.87837 12.9498 7.07319 11.8953 7.46881 11.1237C7.86634 10.3484 8.4329 9.91055 9.03816 9.84111C9.64343 9.77168 10.2944 10.0699 10.8572 10.735C11.4172 11.3969 11.8458 12.38 11.9777 13.5301C12.1097 14.6803 11.9148 15.7349 11.5192 16.5064C11.1217 17.2817 10.5551 17.7196 9.94987 17.789C9.3446 17.8584 8.69362 17.5602 8.13085 16.8951C7.5708 16.2332 7.14224 15.2501 7.0103 14.1Z" />
                  <path id="Ellipse13" d="M4.51578 9.40437C5.64942 9.1696 6.71722 9.26886 7.52122 9.59354C8.32911 9.91978 8.81609 10.4447 8.93964 11.0413C9.06318 11.6378 8.82472 12.313 8.21284 12.9333C7.60391 13.5505 6.66336 14.0657 5.52972 14.3005C4.39608 14.5352 3.32828 14.436 2.52428 14.1113C1.71639 13.7851 1.2294 13.2602 1.10586 12.6636C0.982312 12.067 1.22078 11.3919 1.83266 10.7716C2.44158 10.1543 3.38214 9.63913 4.51578 9.40437Z" />
                  <path id="Ellipse9" d="M5.55268 6.43114C6.68473 6.6735 7.6218 7.19497 8.22658 7.81632C8.83428 8.44067 9.06822 9.1174 8.94067 9.71314C8.81313 10.3089 8.32264 10.8305 7.51257 11.1513C6.70642 11.4706 5.63798 11.5627 4.50594 11.3203C3.37389 11.078 2.43682 10.5565 1.83204 9.93516C1.22433 9.31081 0.990403 8.63408 1.11795 8.03834C1.24549 7.4426 1.73598 6.92097 2.54605 6.60015C3.3522 6.28087 4.42064 6.18877 5.55268 6.43114Z" />
                  <path id="Ellipse10" d="M14.6933 7.66251C15.8502 7.70379 16.8639 8.0539 17.5677 8.56029C18.275 9.06913 18.6233 9.69471 18.6016 10.3036C18.5799 10.9124 18.1878 11.5116 17.4461 11.9687C16.7079 12.4236 15.6719 12.7006 14.515 12.6593C13.358 12.618 12.3444 12.2679 11.6405 11.7615C10.9333 11.2527 10.5849 10.6271 10.6067 10.0183C10.6284 9.40943 11.0204 8.81026 11.7622 8.35313C12.5003 7.89819 13.5363 7.62122 14.6933 7.66251Z" />
                  <path id="Ellipse8" d="M13.9681 12.2103C14.5103 13.2331 14.7135 14.2861 14.6264 15.1488C14.539 16.0157 14.1711 16.63 13.6328 16.9153C13.0946 17.2007 12.3797 17.1604 11.6131 16.7463C10.8502 16.3341 10.0927 15.5751 9.55048 14.5522C9.00823 13.5294 8.8051 12.4764 8.89213 11.6137C8.97958 10.7468 9.34747 10.1325 9.88574 9.84715C10.424 9.56179 11.1389 9.60205 11.9055 10.0162C12.6683 10.4283 13.4258 11.1874 13.9681 12.2103Z" />
                  <circle id="Ellipse3" cx="9.49414" cy="9.49951" r="3" />
                </g>
            </svg>
          </div>

          <div className="flower_container  flower_container_two">
            <svg className='flower  flower_moves' width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="flower">
                  <path id="Ellipse14" d="M7.00421 5.33129C7.87813 6.09058 8.44647 6.99999 8.67563 7.83624C8.9059 8.67654 8.78364 9.38205 8.38406 9.84195C7.98449 10.3018 7.30298 10.5215 6.43875 10.4108C5.57869 10.3007 4.59881 9.86498 3.72489 9.10569C2.85097 8.3464 2.28262 7.43699 2.05346 6.60074C1.82319 5.76044 1.94546 5.05493 2.34503 4.59503C2.74461 4.13513 3.42612 3.91552 4.29035 4.02616C5.15041 4.13626 6.13029 4.572 7.00421 5.33129Z" />
                  <path id="Ellipse15" d="M14.1133 6.75638C13.5164 7.76659 12.714 8.50006 11.9221 8.88207C11.1265 9.26593 10.3999 9.27391 9.86721 8.95917C9.33455 8.64443 8.991 8.0041 8.94339 7.12199C8.89601 6.24412 9.15138 5.18741 9.74829 4.17721C10.3452 3.167 11.1476 2.43353 11.9394 2.05152C12.7351 1.66766 13.4617 1.65967 13.9944 1.97441C14.527 2.28915 14.8706 2.92948 14.9182 3.8116C14.9656 4.68947 14.7102 5.74617 14.1133 6.75638Z" />
                  <path id="Ellipse4" d="M8.9681 4.21271C9.51035 5.23556 9.71348 6.28855 9.62645 7.15125C9.539 8.01813 9.17111 8.63242 8.63284 8.91777C8.09457 9.20313 7.37968 9.16287 6.61311 8.74873C5.85025 8.33659 5.09273 7.5775 4.55048 6.55465C4.00823 5.5318 3.8051 4.47881 3.89213 3.61611C3.97958 2.74923 4.34747 2.13495 4.88574 1.84959C5.42401 1.56423 6.1389 1.6045 6.90547 2.01863C7.66833 2.43077 8.42585 3.18986 8.9681 4.21271Z" />
                  <path id="Ellipse5" d="M12.423 4.98857C12.454 6.14585 12.1678 7.17935 11.7062 7.91341C11.2425 8.65102 10.6399 9.03769 10.0308 9.05397C9.42182 9.07026 8.79938 8.71635 8.29687 8.00458C7.79679 7.29624 7.45575 6.27952 7.4248 5.12223C7.39386 3.96495 7.68007 2.93145 8.14158 2.1974C8.60532 1.45979 9.20796 1.07312 9.81698 1.05683C10.426 1.04054 11.0484 1.39445 11.5509 2.10622C12.051 2.81456 12.3921 3.83129 12.423 4.98857Z" />
                  <path id="Ellipse6" d="M13.9839 9.87344C15.0845 10.2325 15.9621 10.8488 16.4988 11.5298C17.0381 12.2141 17.2003 12.9116 17.0113 13.4908C16.8223 14.07 16.2801 14.5376 15.441 14.7722C14.606 15.0057 13.5338 14.9859 12.4332 14.6269C11.3326 14.2678 10.455 13.6515 9.91825 12.9705C9.37895 12.2862 9.21684 11.5887 9.4058 11.0096C9.59475 10.4304 10.137 9.96271 10.9761 9.72809C11.8111 9.49459 12.8833 9.51438 13.9839 9.87344Z" />
                  <path id="Ellipse7" d="M14.8234 9.08101C13.8688 9.73591 12.8457 10.0573 11.9787 10.0688C11.1074 10.0804 10.4554 9.78464 10.1107 9.28225C9.76607 8.77987 9.72489 8.06503 10.0493 7.2564C10.3721 6.45166 11.0403 5.61284 11.9949 4.95794C12.9496 4.30303 13.9727 3.98163 14.8397 3.97013C15.7109 3.95856 16.363 4.25431 16.7076 4.75669C17.0523 5.25908 17.0935 5.97391 16.7691 6.78255C16.4462 7.58728 15.7781 8.4261 14.8234 9.08101Z" />
                  <path id="Ellipse11" d="M4.57173 12.2002C5.11992 11.1805 5.88185 10.4258 6.6471 10.0181C7.41606 9.60847 8.13117 9.57237 8.66778 9.86086C9.20438 10.1493 9.56868 10.7658 9.65108 11.6331C9.73308 12.4963 9.52383 13.5481 8.97563 14.5678C8.42743 15.5875 7.66551 16.3421 6.90025 16.7498C6.13129 17.1595 5.41618 17.1956 4.87958 16.9071C4.34297 16.6186 3.97867 16.0022 3.89627 15.1348C3.81427 14.2716 4.02353 13.2198 4.57173 12.2002Z" />
                  <path id="Ellipse12" d="M7.0103 14.1C6.87837 12.9498 7.07319 11.8953 7.46881 11.1237C7.86634 10.3484 8.4329 9.91055 9.03816 9.84111C9.64343 9.77168 10.2944 10.0699 10.8572 10.735C11.4172 11.3969 11.8458 12.38 11.9777 13.5301C12.1097 14.6803 11.9148 15.7349 11.5192 16.5064C11.1217 17.2817 10.5551 17.7196 9.94987 17.789C9.3446 17.8584 8.69362 17.5602 8.13085 16.8951C7.5708 16.2332 7.14224 15.2501 7.0103 14.1Z" />
                  <path id="Ellipse13" d="M4.51578 9.40437C5.64942 9.1696 6.71722 9.26886 7.52122 9.59354C8.32911 9.91978 8.81609 10.4447 8.93964 11.0413C9.06318 11.6378 8.82472 12.313 8.21284 12.9333C7.60391 13.5505 6.66336 14.0657 5.52972 14.3005C4.39608 14.5352 3.32828 14.436 2.52428 14.1113C1.71639 13.7851 1.2294 13.2602 1.10586 12.6636C0.982312 12.067 1.22078 11.3919 1.83266 10.7716C2.44158 10.1543 3.38214 9.63913 4.51578 9.40437Z" />
                  <path id="Ellipse9" d="M5.55268 6.43114C6.68473 6.6735 7.6218 7.19497 8.22658 7.81632C8.83428 8.44067 9.06822 9.1174 8.94067 9.71314C8.81313 10.3089 8.32264 10.8305 7.51257 11.1513C6.70642 11.4706 5.63798 11.5627 4.50594 11.3203C3.37389 11.078 2.43682 10.5565 1.83204 9.93516C1.22433 9.31081 0.990403 8.63408 1.11795 8.03834C1.24549 7.4426 1.73598 6.92097 2.54605 6.60015C3.3522 6.28087 4.42064 6.18877 5.55268 6.43114Z" />
                  <path id="Ellipse10" d="M14.6933 7.66251C15.8502 7.70379 16.8639 8.0539 17.5677 8.56029C18.275 9.06913 18.6233 9.69471 18.6016 10.3036C18.5799 10.9124 18.1878 11.5116 17.4461 11.9687C16.7079 12.4236 15.6719 12.7006 14.515 12.6593C13.358 12.618 12.3444 12.2679 11.6405 11.7615C10.9333 11.2527 10.5849 10.6271 10.6067 10.0183C10.6284 9.40943 11.0204 8.81026 11.7622 8.35313C12.5003 7.89819 13.5363 7.62122 14.6933 7.66251Z" />
                  <path id="Ellipse8" d="M13.9681 12.2103C14.5103 13.2331 14.7135 14.2861 14.6264 15.1488C14.539 16.0157 14.1711 16.63 13.6328 16.9153C13.0946 17.2007 12.3797 17.1604 11.6131 16.7463C10.8502 16.3341 10.0927 15.5751 9.55048 14.5522C9.00823 13.5294 8.8051 12.4764 8.89213 11.6137C8.97958 10.7468 9.34747 10.1325 9.88574 9.84715C10.424 9.56179 11.1389 9.60205 11.9055 10.0162C12.6683 10.4283 13.4258 11.1874 13.9681 12.2103Z" />
                  <circle id="Ellipse3" cx="9.49414" cy="9.49951" r="3" />
                </g>
            </svg>
          </div>


          <div className="flower_container  flower_container_three">
            <svg className='flower  flower_moves' width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="flower">
                  <path id="Ellipse14" d="M7.00421 5.33129C7.87813 6.09058 8.44647 6.99999 8.67563 7.83624C8.9059 8.67654 8.78364 9.38205 8.38406 9.84195C7.98449 10.3018 7.30298 10.5215 6.43875 10.4108C5.57869 10.3007 4.59881 9.86498 3.72489 9.10569C2.85097 8.3464 2.28262 7.43699 2.05346 6.60074C1.82319 5.76044 1.94546 5.05493 2.34503 4.59503C2.74461 4.13513 3.42612 3.91552 4.29035 4.02616C5.15041 4.13626 6.13029 4.572 7.00421 5.33129Z" />
                  <path id="Ellipse15" d="M14.1133 6.75638C13.5164 7.76659 12.714 8.50006 11.9221 8.88207C11.1265 9.26593 10.3999 9.27391 9.86721 8.95917C9.33455 8.64443 8.991 8.0041 8.94339 7.12199C8.89601 6.24412 9.15138 5.18741 9.74829 4.17721C10.3452 3.167 11.1476 2.43353 11.9394 2.05152C12.7351 1.66766 13.4617 1.65967 13.9944 1.97441C14.527 2.28915 14.8706 2.92948 14.9182 3.8116C14.9656 4.68947 14.7102 5.74617 14.1133 6.75638Z" />
                  <path id="Ellipse4" d="M8.9681 4.21271C9.51035 5.23556 9.71348 6.28855 9.62645 7.15125C9.539 8.01813 9.17111 8.63242 8.63284 8.91777C8.09457 9.20313 7.37968 9.16287 6.61311 8.74873C5.85025 8.33659 5.09273 7.5775 4.55048 6.55465C4.00823 5.5318 3.8051 4.47881 3.89213 3.61611C3.97958 2.74923 4.34747 2.13495 4.88574 1.84959C5.42401 1.56423 6.1389 1.6045 6.90547 2.01863C7.66833 2.43077 8.42585 3.18986 8.9681 4.21271Z" />
                  <path id="Ellipse5" d="M12.423 4.98857C12.454 6.14585 12.1678 7.17935 11.7062 7.91341C11.2425 8.65102 10.6399 9.03769 10.0308 9.05397C9.42182 9.07026 8.79938 8.71635 8.29687 8.00458C7.79679 7.29624 7.45575 6.27952 7.4248 5.12223C7.39386 3.96495 7.68007 2.93145 8.14158 2.1974C8.60532 1.45979 9.20796 1.07312 9.81698 1.05683C10.426 1.04054 11.0484 1.39445 11.5509 2.10622C12.051 2.81456 12.3921 3.83129 12.423 4.98857Z" />
                  <path id="Ellipse6" d="M13.9839 9.87344C15.0845 10.2325 15.9621 10.8488 16.4988 11.5298C17.0381 12.2141 17.2003 12.9116 17.0113 13.4908C16.8223 14.07 16.2801 14.5376 15.441 14.7722C14.606 15.0057 13.5338 14.9859 12.4332 14.6269C11.3326 14.2678 10.455 13.6515 9.91825 12.9705C9.37895 12.2862 9.21684 11.5887 9.4058 11.0096C9.59475 10.4304 10.137 9.96271 10.9761 9.72809C11.8111 9.49459 12.8833 9.51438 13.9839 9.87344Z" />
                  <path id="Ellipse7" d="M14.8234 9.08101C13.8688 9.73591 12.8457 10.0573 11.9787 10.0688C11.1074 10.0804 10.4554 9.78464 10.1107 9.28225C9.76607 8.77987 9.72489 8.06503 10.0493 7.2564C10.3721 6.45166 11.0403 5.61284 11.9949 4.95794C12.9496 4.30303 13.9727 3.98163 14.8397 3.97013C15.7109 3.95856 16.363 4.25431 16.7076 4.75669C17.0523 5.25908 17.0935 5.97391 16.7691 6.78255C16.4462 7.58728 15.7781 8.4261 14.8234 9.08101Z" />
                  <path id="Ellipse11" d="M4.57173 12.2002C5.11992 11.1805 5.88185 10.4258 6.6471 10.0181C7.41606 9.60847 8.13117 9.57237 8.66778 9.86086C9.20438 10.1493 9.56868 10.7658 9.65108 11.6331C9.73308 12.4963 9.52383 13.5481 8.97563 14.5678C8.42743 15.5875 7.66551 16.3421 6.90025 16.7498C6.13129 17.1595 5.41618 17.1956 4.87958 16.9071C4.34297 16.6186 3.97867 16.0022 3.89627 15.1348C3.81427 14.2716 4.02353 13.2198 4.57173 12.2002Z" />
                  <path id="Ellipse12" d="M7.0103 14.1C6.87837 12.9498 7.07319 11.8953 7.46881 11.1237C7.86634 10.3484 8.4329 9.91055 9.03816 9.84111C9.64343 9.77168 10.2944 10.0699 10.8572 10.735C11.4172 11.3969 11.8458 12.38 11.9777 13.5301C12.1097 14.6803 11.9148 15.7349 11.5192 16.5064C11.1217 17.2817 10.5551 17.7196 9.94987 17.789C9.3446 17.8584 8.69362 17.5602 8.13085 16.8951C7.5708 16.2332 7.14224 15.2501 7.0103 14.1Z" />
                  <path id="Ellipse13" d="M4.51578 9.40437C5.64942 9.1696 6.71722 9.26886 7.52122 9.59354C8.32911 9.91978 8.81609 10.4447 8.93964 11.0413C9.06318 11.6378 8.82472 12.313 8.21284 12.9333C7.60391 13.5505 6.66336 14.0657 5.52972 14.3005C4.39608 14.5352 3.32828 14.436 2.52428 14.1113C1.71639 13.7851 1.2294 13.2602 1.10586 12.6636C0.982312 12.067 1.22078 11.3919 1.83266 10.7716C2.44158 10.1543 3.38214 9.63913 4.51578 9.40437Z" />
                  <path id="Ellipse9" d="M5.55268 6.43114C6.68473 6.6735 7.6218 7.19497 8.22658 7.81632C8.83428 8.44067 9.06822 9.1174 8.94067 9.71314C8.81313 10.3089 8.32264 10.8305 7.51257 11.1513C6.70642 11.4706 5.63798 11.5627 4.50594 11.3203C3.37389 11.078 2.43682 10.5565 1.83204 9.93516C1.22433 9.31081 0.990403 8.63408 1.11795 8.03834C1.24549 7.4426 1.73598 6.92097 2.54605 6.60015C3.3522 6.28087 4.42064 6.18877 5.55268 6.43114Z" />
                  <path id="Ellipse10" d="M14.6933 7.66251C15.8502 7.70379 16.8639 8.0539 17.5677 8.56029C18.275 9.06913 18.6233 9.69471 18.6016 10.3036C18.5799 10.9124 18.1878 11.5116 17.4461 11.9687C16.7079 12.4236 15.6719 12.7006 14.515 12.6593C13.358 12.618 12.3444 12.2679 11.6405 11.7615C10.9333 11.2527 10.5849 10.6271 10.6067 10.0183C10.6284 9.40943 11.0204 8.81026 11.7622 8.35313C12.5003 7.89819 13.5363 7.62122 14.6933 7.66251Z" />
                  <path id="Ellipse8" d="M13.9681 12.2103C14.5103 13.2331 14.7135 14.2861 14.6264 15.1488C14.539 16.0157 14.1711 16.63 13.6328 16.9153C13.0946 17.2007 12.3797 17.1604 11.6131 16.7463C10.8502 16.3341 10.0927 15.5751 9.55048 14.5522C9.00823 13.5294 8.8051 12.4764 8.89213 11.6137C8.97958 10.7468 9.34747 10.1325 9.88574 9.84715C10.424 9.56179 11.1389 9.60205 11.9055 10.0162C12.6683 10.4283 13.4258 11.1874 13.9681 12.2103Z" />
                  <circle id="Ellipse3" cx="9.49414" cy="9.49951" r="3" />
                </g>
            </svg>
          </div>
        </>
    )
}

export default Flowers;