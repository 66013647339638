import React from "react";

type BigCardProps = {
	bannerImagesClass: string;
	title: string;
    title2?: string;
	description: string;
};

const Banner = ({ bannerImagesClass, title, title2, description }: BigCardProps) => {
	return (
		<div className={`banner ${bannerImagesClass}`}>
			<div className="back"></div>
			<p className="accent_text">ОЖИДАЙТЕ</p>
			<div className="text">
				<h2 className="banner__title"><span>{title}</span><span>{title2}</span></h2>
				<p> {description}</p>
			</div>
		</div>
	);
};

export default Banner;
