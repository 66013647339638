import React, { useEffect } from "react";
import Link from "next/link";
import Head from "next/head";
import Card from "../components/card";
import Image from "next/image";
// import { useData } from '../hooks/useData';
import sanityClient from "../../public/support-func/sanityClient";
import { sortByDate } from "../../public/support-func/support.js";
import img from "../../public/image/author_page/main1.png";
import me from "../../public/image/author_page/1.jpg";
import Flowers from "../components/flowers";
import { groq } from "next-sanity";
import Banner from "../components/banner";

export async function getStaticProps() {
	const pageMeta = await sanityClient.fetch(
		`*[_type == "metadata" && title == "/"] `
	);
	const pageData = await sanityClient.fetch(
		`*[_type == "stories" && active == true]`
	);
	const categories = await sanityClient.fetch(
		`*[_type == "categories" && activeCategory == true]`
	);
	const postQuery = groq`*[_type == "stories" && active == true && isPremier == true][0]`;
	const premierData = await sanityClient.fetch(postQuery);

	return {
		props: {
			pageMeta: pageMeta[0],
			pageData,
			premierTitle: premierData?.title,
			categories,
		},
		revalidate: 300,
	};
}

const AuthorPage = ({ pageMeta, pageData, premierTitle, categories }) => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<Head>
				<title>{pageMeta.meta_title}</title>
				<meta name="description" content={pageMeta.meta_description} />
				<meta name="keywords" content={pageMeta.meta_keywords} />
				<meta name="verification" content="vp6LcN6cIDxzbadWL8bD-A" />
			</Head>

			{/* секция голосования */}
			{/* <section className='section  made_new_story'> 
            <div className='made_new_story__link_container '>
              <Link to="/made-new-story" className='made_new_story__link'>Учавствуй в создании истории</Link>
              <div className='comet'></div>
            </div>

            <div className='made_new_story__desc'>
              <p>Голосуй за то, какие герои стануть героями нового рассказа</p>
            </div>
          </section> */}
			<Flowers />

			<Banner
				bannerImagesClass="fin_fin"
				title="Фин Фин"
				description="В работе текстовый сериал о девушке сироте Фин Фин. Она живет в мире, где каждый человек сразу после школы обязан выбрать дело жизни. Фин Фин не может определиться и чтобы наконец разобраться в себе, она хочет раскрыть тайну своего происхождения, узнать кем были ее родители."
			/>

			<section className="section about_me">
				<div>
					<h4>Здравствуй, путник!</h4>
					<p>
						{" "}
						Надеюсь, ты приятно проведешь время на моем сайте. Меня
						зовут Алина Авелис, я пишу рассказы и книги в жанре
						приключенческой фэнтези. Конечно, иногда ты можешь
						встретить рассказы и в других жанрах, надеюсь, они тебе
						тоже понравятся. Я писала их, поддаваясь порыву
						настроения. Всегда буду рада, если ты поддержишь мое
						творчество, нажав на кнопку &quot;Поддержать&quot;.
					</p>
				</div>

				<Image
					className="big_img__img"
					src={me}
					alt=""
					width={150}
					height={200}
				/>
			</section>

			{/* секция премьера */}
			<section className="section">
				<p className="premier">
					<span className="accent_text">Премьера </span>
					<span>
						{" "}
						- <Link href="/premier">{premierTitle}</Link>
					</span>
				</p>
			</section>

			<section className="section author_page__links">
				<div className="flexbox-rows space-between">
					<h2 className="h2">Рассказы автора</h2>
					<Link className="h4 accent_text" href={`/categories`}>
						Все рассказы
					</Link>
				</div>
				<div className="story_cards">
					{sortByDate(pageData)
						?.slice(0, 3)
						.map((item: any) => {
							return (
								<Card
									key={item._id}
									title={item.title}
									text={item.shortDescription}
									linkToStory={`/stories/${item.slug.current}`}
									linkToCategory={`/categories/${
										categories.find(
											(i) => i._id === item.category._ref
										)?.slug.current
									}`}
								/>
							);
						})}
				</div>
			</section>

			<div className="big_img">
				<Image
					className="big_img__img"
					src={img}
					alt=""
					width={200}
					height={200}
				/>
			</div>

			<section className="section author_page__links">
				<div className="flexbox-rows space-between">
					<h2 className="h2">Категории автора</h2>
					<Link className="h4 accent_text" href={`/categories`}>
						Все категории
					</Link>
				</div>
				<div className="story_cards">
					{categories?.slice(0, 3).map((item: any, i: number) => {
						const text = (
							<ul>
								{sortByDate(pageData)
									?.filter(
										(point: any) =>
											point.category._ref === item._id
									)
									.map((p: any, i: number) => {
										return (
											<li
												key={i + p.slug.current}
												className="card__list_item"
											>
												<Link
													className="simple_link"
													href={`/stories/${p.slug.current}`}
												>
													{p.title}
												</Link>
											</li>
										);
									})}
							</ul>
						);

						return (
							<div className="card" key={i + "ch"}>
								<h3 className="card__title">{item.title}</h3>

								{text}

								<div className="card__links">
									<Link
										href={`/categories/${item.slug.current}`}
									>
										Перейти в категорию
									</Link>
								</div>
							</div>
						);
					})}
				</div>
			</section>

			{/* <section className='section author_page__links'>
            <Link className='h2 title_link' href="/books">Книги автора</Link>
          </section> */}
		</>
	);
};

export default AuthorPage;
